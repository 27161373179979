import styled from 'styled-components';
import { SpacingType, spacingUtils } from 'utils/helpers/spacingUtils';

interface LoaderContainerProps extends SpacingType {}

export const LoaderContainer = styled.div<LoaderContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  ${spacingUtils}
`;
