import { useAuth } from 'modules/auth/providers/AuthProvider';
import UserAvatar from 'stories/UserAvatar';
import { Identifications } from 'ui/theme/Header/Navbar/styles';

import { ClientSwitch } from '../ClientSwitch';
import { Copyright } from '../Copyright';
import { NavbarLinks } from '../NavbarLinks';
import {
  SidebarDim,
  SidebarV1ContentWrapper,
  SidebarV1Wrapper,
  SidebarV2BottomWrapper,
  SidebarV2ContentWrapper,
  SidebarV2TopWrapper,
  SidebarV2Wrapper,
  SidebarV3BottomWrapper,
  SidebarV3ContentWrapper,
  SidebarV3TopWrapper,
  SidebarV3Wrapper,
} from './styles';

interface SidebarProps {
  variant: number;
  isOpen?: boolean;
  mode?: 'light' | 'dark';
  linksType?: 'home' | 'app' | 'platform-manager' | 'crm';
  handleClose?: () => void;
}

export function Sidebar({
  variant,
  isOpen,
  mode,
  linksType,
  handleClose,
}: SidebarProps) {
  const { state, logout } = useAuth();

  const user = {
    firstName: state.userJob?.contact?.firstName || '',
    lastName: state.userJob?.contact?.lastName || '',
  };

  const handleLogout = () => {
    logout();
    handleClose && handleClose();
  };

  switch (variant) {
    case 1: {
      return (
        <SidebarV1Wrapper isOpen={isOpen} mode={mode}>
          <SidebarV1ContentWrapper>
            <NavbarLinks type={linksType} />
            <Copyright pos="center" mode={mode} />
          </SidebarV1ContentWrapper>
        </SidebarV1Wrapper>
      );
    }
    case 2: {
      return (
        <>
          <SidebarDim active={isOpen ?? false} onClick={handleClose} />
          <SidebarV2Wrapper active={Boolean(isOpen)} side="left" mode={mode}>
            <SidebarV2ContentWrapper>
              <SidebarV2TopWrapper>
                {state.loggedIn && (
                  <Identifications>
                    <UserAvatar
                      size="large"
                      type="monogram"
                      user={user}
                      withFullNameLabel
                    />
                  </Identifications>
                )}
                <ClientSwitch callback={handleLogout} />
                <NavbarLinks type={linksType} callback={handleClose} />
              </SidebarV2TopWrapper>

              <SidebarV2BottomWrapper>
                <Copyright mode={mode} />
              </SidebarV2BottomWrapper>
            </SidebarV2ContentWrapper>
          </SidebarV2Wrapper>
        </>
      );
    }
    case 3: {
      return (
        <>
          <SidebarDim active={isOpen} onClick={handleClose} />
          <SidebarV3Wrapper active={Boolean(isOpen)} side="left" mode={mode}>
            <SidebarV3ContentWrapper>
              <SidebarV3TopWrapper>
                <NavbarLinks type={linksType} callback={handleClose} />
              </SidebarV3TopWrapper>
              <SidebarV3BottomWrapper>
                <Copyright mode={mode} />
              </SidebarV3BottomWrapper>
            </SidebarV3ContentWrapper>
          </SidebarV3Wrapper>
        </>
      );
    }
    default: {
      return null;
    }
  }
}
