import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useClient } from 'modules/settings/providers/ClientProvider';
import React, { useEffect, useRef, useState } from 'react';
import theme from 'stories/theme';
import { AppLoader } from 'ui/AppLoader';
import GlobalStyle from 'ui/theme/global-style';
import TimerLoader from 'ui/TimerLoader';
import { renderSize } from 'utils/helpers/renderSize';
import { useMaxWidth } from 'utils/hooks/useWindow';

import Footer from './Footer';
import Header from './Header';
import { useResizeObserver } from './hooks/useResizeObserver';
import { ChildrenWrapper, Wrapper } from './styles';
import {
  CustomStylesType,
  DataType,
  DeviceType,
  FooterParamsType,
} from './types';

interface LayoutProps {
  children: React.ReactNode;
  headerVariant?: number;
  footerVariant?: number;
  footerParams?: FooterParamsType;
  data?: DataType;
  customStyles?: CustomStylesType;
}

export default function Layout({
  children,
  headerVariant,
  footerVariant,
  footerParams = {},
  data = {},
  customStyles = {},
}: LayoutProps) {
  const { restartTimeLeft } = useClient();
  const isMobile = useMaxWidth('md');
  const {
    state: { loading },
  } = useAuth();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [minChildrenHeight, setMinChildrenHeight] = useState('100vh');

  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useResizeObserver(headerRef, (rect) => {
    if (headerVariant) {
      setHeaderHeight(rect.height);
    }
  });

  useResizeObserver(footerRef, (rect) => {
    if (footerVariant) {
      setFooterHeight(rect.height);
    }
  });

  useEffect(() => {
    const isSkipFooterHeight = customStyles.children?.skipFooterHeight?.value;
    const isSkipHeaderHeight = customStyles.children?.skipHeaderHeight?.value;

    const isDeviceMatch = (isMobile: boolean, device?: DeviceType) =>
      !device ||
      device === 'both' ||
      (isMobile && device === 'mobile') ||
      (!isMobile && device === 'desktop');

    const calculatedFooterHeight =
      isSkipFooterHeight &&
      isDeviceMatch(isMobile, customStyles.children?.skipFooterHeight?.device)
        ? 0
        : footerHeight;

    const calculatedHeaderHeight =
      isSkipHeaderHeight &&
      isDeviceMatch(isMobile, customStyles.children?.skipHeaderHeight?.device)
        ? 0
        : headerHeight;

    setMinChildrenHeight(
      `calc(100vh - ${calculatedHeaderHeight}px - ${calculatedFooterHeight}px)`
    );
  }, [headerHeight, footerHeight, customStyles, isMobile]);

  const childrenMinHeight = customStyles.children?.minHeight
    ? renderSize(customStyles.children?.minHeight)
    : minChildrenHeight;

  const childrenMarginTop = renderSize(headerHeight);

  const childrenPaddingTop = renderSize(customStyles.children?.paddingTop);

  const childrenPaddingBottom =
    customStyles.children?.paddingBottom === undefined && headerHeight
      ? theme.spacing[23]
      : renderSize(customStyles.children?.paddingBottom);

  if (restartTimeLeft) return <TimerLoader timeLeft={restartTimeLeft} />;

  return (
    <>
      <GlobalStyle />
      <Wrapper backgroundColor={customStyles.backgroundColor}>
        {headerVariant && (
          <Header
            ref={headerRef}
            isMobile={isMobile}
            variant={headerVariant}
            data={data}
          />
        )}
        <ChildrenWrapper
          minHeight={childrenMinHeight}
          marginTop={childrenMarginTop}
          paddingTop={childrenPaddingTop}
          paddingBottom={childrenPaddingBottom}
        >
          {loading ? <AppLoader /> : children}
        </ChildrenWrapper>
        {footerVariant && (
          <Footer
            ref={footerRef}
            isMobile={isMobile}
            variant={footerVariant}
            data={data}
            footerParams={footerParams}
          />
        )}
      </Wrapper>
    </>
  );
}
