import React from 'react';
import { useTheme } from 'styled-components';
import Loader from 'ui/Loader';
import { SpacingType } from 'utils/helpers/spacingUtils';

import { LoaderContainer } from './style';

interface AppLoader extends SpacingType {
  height?: string;
  width?: string;
}

export const AppLoader = ({
  height = '100',
  width = '100',
  spacing,
}: AppLoader) => {
  const theme = useTheme();
  return (
    <LoaderContainer data-testid="loader" spacing={spacing}>
      <Loader
        toColor={theme.colors.primary[450]}
        height={height}
        width={width}
        dPath="M10 50a40 40 0 0080 0 40 42 0 01-80 0"
      />
    </LoaderContainer>
  );
};
