import React, { useState } from 'react';

import { Path, Svg } from './styles';

interface BurgerIconProps {
  height?: number;
  width?: number;
  color?: string;
  pressColor?: string;
  isOpen?: boolean;
}

export const BurgerIcon: React.FC<BurgerIconProps> = ({
  height = 24,
  width = 24,
  color = '#AAAAAA',
  pressColor,
  isOpen,
}: BurgerIconProps) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    if (pressColor) {
      setIsPressed(true);
    }
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const appliedColor = isPressed && pressColor ? pressColor : color;

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Path
        className={isOpen ? 'top-bun open' : 'top-bun'}
        d="M3 6H21"
        stroke={appliedColor}
        strokeWidth="2"
      />
      <Path
        className={isOpen ? 'meat open' : 'meat'}
        d="M3 12H21"
        stroke={appliedColor}
        strokeWidth="2"
      />
      <Path
        className={isOpen ? 'bottom-bun open' : 'bottom-bun'}
        d="M3 18H21"
        stroke={appliedColor}
        strokeWidth="2"
      />
    </Svg>
  );
};
