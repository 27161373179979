import { ModeType } from 'interfaces';
import React from 'react';
import { BurgerIcon } from 'ui/Icons/BurgerIcon';

import { Wrapper } from './styles';

interface BurgerProps {
  mode?: ModeType;
  isOpen?: boolean;
  color?: string;
  pressColor?: string;
  height?: number;
  width?: number;
  handleClick?: () => void;
}

export function Burger({
  mode,
  isOpen,
  color,
  pressColor,
  width = 24,
  height = 24,
  handleClick,
}: BurgerProps) {
  return (
    <Wrapper onClick={handleClick} width={width} height={height} mode={mode}>
      <BurgerIcon
        isOpen={isOpen}
        color={color}
        pressColor={pressColor}
        width={width}
        height={height}
      />
    </Wrapper>
  );
}
