import { Title } from 'stories/Typography';
import { SpacingType } from 'utils/helpers/spacingUtils';

import { StyledUserAvatar, UserAvatarWrapper } from './styles';
import { UserAvatarSizeType, UserAvatarStyleType, UserType } from './types';

interface UserAvatarProps extends SpacingType {
  size?: UserAvatarSizeType;
  type: UserAvatarStyleType;
  user?: UserType;
  withFullNameLabel?: boolean;
}

const formatInitials = (user: UserType): string =>
  `${user.firstName?.[0] ?? ''}${user.lastName?.[0] ?? ''}`;

const formatNames = (user: UserType): string => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.firstName ?? user.lastName ?? '';
};

export default function UserAvatar({
  size = 'standard',
  type,
  user,
  withFullNameLabel,
  spacing,
}: UserAvatarProps) {
  const sizeStylesMapper = {
    xl: {
      width: 60,
      height: 60,
      fontSize: 24,
      labelFontSize: 18,
    },
    large: {
      width: 40,
      height: 40,
      fontSize: 16,
      labelFontSize: 18,
    },
    standard: {
      width: 32,
      height: 32,
      fontSize: 14,
      labelFontSize: 16,
    },
    small: {
      width: 24,
      height: 24,
      fontSize: 10,
      labelFontSize: 14,
    },
    xs: {
      width: 16,
      height: 16,
      fontSize: 7,
      labelFontSize: 12,
    },
  };

  const sizeStyles = sizeStylesMapper[size];

  return (
    <UserAvatarWrapper spacing={spacing}>
      <StyledUserAvatar type={type} {...sizeStyles}>
        {user && formatInitials(user)}
      </StyledUserAvatar>
      {withFullNameLabel && user && (
        <Title customSize={sizeStyles.labelFontSize}>{formatNames(user)}</Title>
      )}
    </UserAvatarWrapper>
  );
}
