import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const InvestorEditsIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15.6667V13.4667C10 13.2889 10.0333 13.1167 10.1 12.95C10.1667 12.7833 10.2667 12.6333 10.4 12.5L17.3667 5.56667C17.5667 5.36667 17.7889 5.22222 18.0333 5.13333C18.2778 5.04444 18.5222 5 18.7667 5C19.0333 5 19.2889 5.05 19.5333 5.15C19.7778 5.25 20 5.4 20.2 5.6L21.4333 6.83333C21.6111 7.03333 21.75 7.25556 21.85 7.5C21.95 7.74444 22 7.98889 22 8.23333C22 8.47778 21.9556 8.72778 21.8667 8.98333C21.7778 9.23889 21.6333 9.46667 21.4333 9.66667L14.5 16.6C14.3667 16.7333 14.2167 16.8333 14.05 16.9C13.8833 16.9667 13.7111 17 13.5333 17H11.3333C10.9556 17 10.6389 16.8722 10.3833 16.6167C10.1278 16.3611 10 16.0444 10 15.6667ZM12 15H13.2667L17.3 10.9333L16.7 10.3L16.0667 9.7L12 13.7333V15ZM16.7 10.3L16.0667 9.7L17.3 10.9333L16.7 10.3Z"
        fill={fillColor}
      />
      <path
        d="M8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 14V13.2C0 12.65 0.141667 12.1333 0.425 11.65C0.708333 11.1667 1.1 10.8 1.6 10.55C2.45 10.1167 3.40833 9.75 4.475 9.45C5.54167 9.15 6.71667 9 8 9H8.35C8.45 9 8.55 9.01667 8.65 9.05C8.51667 9.35 8.40417 9.6625 8.3125 9.9875C8.22083 10.3125 8.15 10.65 8.1 11H8C6.81667 11 5.75417 11.15 4.8125 11.45C3.87083 11.75 3.1 12.05 2.5 12.35C2.35 12.4333 2.22917 12.55 2.1375 12.7C2.04583 12.85 2 13.0167 2 13.2V14H8.3C8.4 14.35 8.53333 14.6958 8.7 15.0375C8.86667 15.3792 9.05 15.7 9.25 16H2C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z"
        fill={fillColor}
      />
    </Svg>
  );
};
