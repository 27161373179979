import theme from 'stories/theme';
import styled from 'styled-components';

export const Wrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.grey[600]};
  padding: ${theme.spacing[12]} ${theme.spacing[28.75]} ${theme.spacing[12]}
    ${theme.spacing[28.75]};
  @media (max-width: ${theme.breakpoints.md}) {
    display: block;
    padding: ${theme.spacing[4]} ${theme.spacing[8]} ${theme.spacing[4]}
      ${theme.spacing[8]};
    text-align: center;
  }

  p {
    margin: 0;
  }
`;

export const SubWrapper = styled.div`
  display: inline-block;
`;

export const Links = styled.div`
  display: flex;
  justify-content: right;
  gap: 5.62rem;
  text-align: right;
  @media (max-width: 960px) {
    display: block;
    margin-top: 2rem;
    text-align: center;
  }
`;

export const SubLinks = styled.div`
  a {
    color: ${theme.colors.grey[0]};
    display: block;
    font-size: 12pt;
    font-family: ${theme.fonts.family.secondary};
    padding: 1rem;
  }
`;

export const Address = styled.div`
  margin: 1rem 0;
  font-family: ${theme.fonts.family.secondary};
  color: ${theme.colors.grey[0]};
`;

export const Phone = styled.div`
  a {
    color: ${theme.colors.grey[0]};
    font-size: 12pt;
    font-family: ${theme.fonts.family.secondary};
  }
  maring-bottom: 0.5rem;
`;

export const Email = styled.div`
  a {
    color: ${theme.colors.grey[0]};
    font-size: 12pt;
    font-family: ${theme.fonts.family.secondary};
  }
  margin-bottom: 0.5rem;
`;

export const RecaptchWrapper = styled.div`
  margin-top: 0.5rem;
  width: 50%;
  div {
    text-align: left;
  }
  @media (max-width: 960px) {
    display: block;
    div {
      text-align: center;
    }
    width: 100%;
    margin-bottom: 2rem;
  }
`;

export const Option = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
