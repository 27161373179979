import styled from 'styled-components';
import { SpacingType, spacingUtils } from 'utils/helpers/spacingUtils';

import { UserAvatarStyleType } from './types';

interface UserAvatarWrapperProps extends SpacingType {}

export const UserAvatarWrapper = styled.div<UserAvatarWrapperProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
  ${spacingUtils}
`;

interface StyledUserAvatarWrapperProps {
  type: UserAvatarStyleType;
  width: number;
  height: number;
  fontSize: number;
}

export const StyledUserAvatar = styled.div<StyledUserAvatarWrapperProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  font-size: ${({ fontSize }) => fontSize}px;
  color: ${({ theme }) => theme.colors.grey[0]};
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.grey[400]} 0%,
    ${({ theme }) => theme.colors.grey[700]} 100%
  );
`;
