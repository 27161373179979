function footerData(client: string) {
  switch (client) {
    case 'core':
      return {
        address: ['110 N Wacker Dr, Suite 2200', 'Chicago, IL 60606'],
        phone: '312.566.4880',
        email: 'fundadmin@coreipfund.com',
        links: [
          {
            title: 'Privacy Policy',
            href: 'https://coreipfund.com/privacy-policy/',
          },
          {
            title: 'Regulatory Info',
            href: 'https://coreipfund.com/regulatory-information/',
          },
        ],
      };
    case 'metro':
      return {
        address: ['60 Cutter Mill Road, Suite 200', 'Great Neck, NY 11021'],
        phone: '212.835.9040',
        email: 'info@metreal.com',
      };
    case 'jvm':
      return {
        address: ['903 COMMERCE DRIVE, Suite 250', 'OAK BROOK, IL 60523'],
        phone: '630.242.1000',
        email: 'INFO@JVMREALTY.COM',
      };
    case 'solyco':
      return {
        address: ['400 Water Street, Suite 203', 'Rochester, MI 48307'],
        phone: '947.208.1900',
        email: 'info@solycocapital.com',
      };
    case 'rmr':
      return {
        address: ['3340 Peachtree Rd NE, Suite 2250', 'Atlanta, GA  30326'],
        phone: '404.812.8218',
        email: 'portaladmin-residentialinvestors@rmrgroup.com',
      };
    case 'wellspring':
      return {
        address: ['605 Third Avenue – 44 Floor', 'New York, NY 10158'],
        links: [
          {
            title: 'Privacy Policy',
            href: 'https://www.wellspringcapital.com/privacy/',
          },
          {
            title: 'Terms of Service',
            href: 'https://www.wellspringcapital.com/terms-of-service/',
          },
        ],
      };
    case 'live oak':
      return {
        address: ['200 31st Avenue North Nashville, TN 37203'],
        links: [
          {
            title: 'Privacy Policy',
            href: 'https://lo3capital.com/privacy-policy/',
          },
        ],
      };
    default:
      return {
        address: ['890 Winter St, Suite 325,', 'Waltham, MA 02451'],
        phone: '781.250.4000',
        email: 'support@relevant.us',
        links: [
          {
            title: 'Current Offerings',
            href: '#',
          },
          {
            title: 'Investment Summary',
            href: '#',
          },
          {
            title: 'Documents',
            href: '#',
          },
          {
            title: 'Tax Center',
            href: '#',
          },
          {
            title: 'Support',
            href: '#',
          },
          {
            title: 'Privacy Policy',
            href: '#',
          },
          {
            title: 'Legal',
            href: '#',
          },
          {
            title: 'Terms and Conditions',
            href: '#',
          },
        ],
      };
  }
}

export default footerData;
