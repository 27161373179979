import { useAuth } from 'modules/auth/providers/AuthProvider';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import theme from 'stories/theme';
import UserAvatar from 'stories/UserAvatar';
import { ArrowRight, Close, Pen } from 'ui/Icons';

import { useUserInfo } from '../hooks/useUserInfo';
import NavbarLink from '../NavbarLink';
import { getInitialsFromUserJob } from '../utils/getInitialsFromUserJob';
import { getInputValueFromUserInfo } from '../utils/getInputValueFromUserInfo';
import inputsConfig from './profileInputsConfig.json';
import {
  AvatarAndEditWrapper,
  AvatarWrapper,
  CloseWrapper,
  CustomEditButton,
  InputCardContentWrapper,
  InputCardLabel,
  InputCardRedirectWrapper,
  InputCardValue,
  InputCardWrapper,
  InputsCardsWrapper,
  ModalTitle,
  ProfileModalContentWrapper,
  ProfileModalHeaderWrapper,
  ProfileModalWrapper,
  ProfileWrapper,
  SignOutButtonWrapper,
} from './styles';

interface AvatarProps {
  initials?: string;
  size?: 'small' | 'medium' | 'large';
}

interface EditButtonProps {
  label?: string;
  icon?: React.ReactNode;
  handleClick?: () => void;
}

interface InputCardProps {
  label?: string;
  value?: string;
  handleClick?: () => void;
}

interface ProfileModalProps {
  initials?: string;
  isOpen?: boolean;
  handleClose?: () => void;
}

interface ProfileProps {
  isMobile?: boolean;
}

const Avatar = ({ initials, size }: AvatarProps) => (
  <AvatarWrapper size={size}>{initials}</AvatarWrapper>
);

const EditButton = ({ icon, label, handleClick }: EditButtonProps) => (
  <CustomEditButton onClick={handleClick}>
    {icon} {label}
  </CustomEditButton>
);

const InputCard = ({ label, value, handleClick }: InputCardProps) => (
  <InputCardWrapper>
    <InputCardContentWrapper>
      <InputCardLabel>{label}</InputCardLabel>
      <InputCardValue>{value}</InputCardValue>
    </InputCardContentWrapper>
    <InputCardRedirectWrapper onClick={handleClick}>
      <ArrowRight variant={3} height={24} />
    </InputCardRedirectWrapper>
  </InputCardWrapper>
);

const ProfileModal = ({ initials, isOpen, handleClose }: ProfileModalProps) => {
  const router = useRouter();
  const { logout } = useAuth();
  const { userInfo } = useUserInfo();

  const handleRedirect = (path: string) => {
    router.push(path);
  };

  if (isOpen === undefined) return null;

  return (
    <ProfileModalWrapper isOpen={isOpen}>
      <ProfileModalContentWrapper>
        <ProfileModalHeaderWrapper>
          <CloseWrapper onClick={handleClose}>
            <Close inactive fillColor={theme.colors.grey[400]} />
          </CloseWrapper>
          <AvatarAndEditWrapper>
            <Avatar initials={initials} size="large" />
            <EditButton
              icon={<Pen noHover />}
              label="Edit"
              handleClick={() => handleRedirect('/settings')}
            />
          </AvatarAndEditWrapper>
        </ProfileModalHeaderWrapper>

        <ModalTitle>{`${userInfo?.firstName} ${userInfo?.lastName}`}</ModalTitle>

        <InputsCardsWrapper>
          {inputsConfig.map(({ label, fields, redirectTo }, index) => (
            <InputCard
              key={index}
              label={label}
              value={getInputValueFromUserInfo({ userInfo, fields })}
              handleClick={() => handleRedirect(redirectTo)}
            />
          ))}
        </InputsCardsWrapper>
        <SignOutButtonWrapper onClick={() => logout()}>
          <NavbarLink label="Sign Out" href="/signout" withoutLink />
        </SignOutButtonWrapper>
      </ProfileModalContentWrapper>
    </ProfileModalWrapper>
  );
};

export function Profile({ isMobile }: ProfileProps) {
  const {
    state: { userJob, loggedIn },
  } = useAuth();

  const [isOpen, setIsOpen] = useState<boolean>();

  const initials = getInitialsFromUserJob(userJob);

  const user = {
    firstName: userJob?.contact?.firstName || '',
    lastName: userJob?.contact?.lastName || '',
  };

  const handleClose = () => setIsOpen(false);

  if (!loggedIn) return null;

  if (isMobile) {
    return (
      <>
        <ProfileWrapper onClick={() => setIsOpen(true)}>
          <UserAvatar size="large" type="monogram" user={user} />
        </ProfileWrapper>
        <ProfileModal
          initials={initials}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      </>
    );
  } else {
    return (
      <>
        <Link href="/settings">
          <ProfileWrapper>
            <UserAvatar size="large" type="monogram" user={user} />
          </ProfileWrapper>
        </Link>
      </>
    );
  }
}
