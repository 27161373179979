import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useClient } from 'modules/settings/providers/ClientProvider';
import React from 'react';
import theme from 'stories/theme';
import Divider from 'ui/Divider';
import Loader from 'ui/Loader';
import Notification from 'ui/Notification';
import Select from 'ui/Select';

import { ClientsWrapper, LoaderWrapper } from './styles';

interface ClientSwitchProps {
  callback?: () => void;
}

export function ClientSwitch({ callback }: ClientSwitchProps) {
  const {
    clients,
    client,
    isLoading: isClientLoading,
    errorMessage: clientErrorMessage,
    isClientSwitchHidden,
    handleClientChange,
    handleErrorReset: handleClientErrorReset,
  } = useClient();

  const { state } = useAuth();

  if (isClientSwitchHidden || !state.loggedIn || !clients.length) return null;

  return (
    <>
      <ClientsWrapper>
        <Select
          options={clients}
          value={clients.find((option) => option.value === client)}
          noDefaultColor
          onChange={(option) =>
            handleClientChange(option?.value || 'relevant', callback)
          }
          size="small"
        />
        {isClientLoading && (
          <LoaderWrapper>
            <Loader toColor={theme.colors.primary.main} />
          </LoaderWrapper>
        )}
        {clientErrorMessage && (
          <Notification
            state="danger"
            msg={clientErrorMessage}
            onClick={handleClientErrorReset}
          />
        )}
      </ClientsWrapper>
      <Divider marginTop={16} marginBottom={0} />
    </>
  );
}
