import { ModeType } from 'interfaces';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { DashboardIcon } from 'stories/Icons/Dashboard';
import { InvestorEditsIcon } from 'stories/Icons/InvestorEdits';
import { InvestorsIcon } from 'stories/Icons/Investors';
import { OfferingsIcon } from 'stories/Icons/Offerings';
import { SettingsIcon } from 'stories/Icons/Settings';
import { SubmissionsIcon } from 'stories/Icons/Submissions';
import { DefaultTheme, useTheme } from 'styled-components';
import {
  DocIcon,
  InvestmentIcon,
  MySettingsIcon,
  SignIn as SignInIcon,
  SignOutIcon,
  TagIcon,
  TaxCenterIcon,
} from 'ui/Icons';

import { NavbarLinkWrapper } from './styles';

interface GetNavbarLinkIconProps {
  href: string;
  isActive: boolean;
  theme: DefaultTheme;
}

type GetActiveBarProps = GetNavbarLinkIconProps;

interface NavbarLinkProps {
  mode?: ModeType;
  label: string;
  href: string;
  withoutLink?: boolean;
  handleClick?: () => void;
}

const getNavbarLinkIcon = ({
  href,
  isActive,
  theme,
}: GetNavbarLinkIconProps) => {
  switch (href) {
    case '/current-offerings': {
      return <TagIcon isActive={isActive} />;
    }
    case '/dashboard': {
      return (
        <InvestmentIcon
          isActive={isActive}
          fillColor={
            theme.client === 'solyco' ? theme.colors.primary[500] : undefined
          }
        />
      );
    }
    case '/documents': {
      return (
        <DocIcon
          isActive={isActive}
          fillColor={
            theme.client === 'solyco' ? theme.colors.primary[500] : undefined
          }
        />
      );
    }
    case '/tax-center': {
      return (
        <TaxCenterIcon
          isActive={isActive}
          fillColor={
            theme.client === 'solyco' ? theme.colors.primary[500] : undefined
          }
        />
      );
    }
    case '/settings': {
      return (
        <MySettingsIcon
          isActive={isActive}
          fillColor={
            theme.client === 'solyco' ? theme.colors.primary[500] : undefined
          }
        />
      );
    }
    case '/signout': {
      return (
        <SignOutIcon
          isActive={isActive}
          fillColor={
            theme.client === 'solyco' ? theme.colors.primary[500] : undefined
          }
        />
      );
    }
    case '/': {
      return <SignInIcon />;
    }
    case '/admin/dashboard': {
      return (
        <DashboardIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/admin/offerings': {
      return (
        <OfferingsIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/admin/investors': {
      return (
        <InvestorsIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/admin/investorEdits': {
      return (
        <InvestorEditsIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/admin/submissions': {
      return (
        <SubmissionsIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/admin/settings': {
      return (
        <SettingsIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/salesrep/offerings': {
      return (
        <OfferingsIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/salesrep/investors': {
      return (
        <InvestorsIcon
          width="36px"
          height="36px"
          fillColor={isActive ? theme.colors.grey[0] : theme.colors.grey[400]}
        />
      );
    }
    case '/salesrep/profile': {
      return <MySettingsIcon width="36px" height="36px" isActive={isActive} />;
    }
  }
};

const getActiveBarColor = ({ href, isActive, theme }: GetActiveBarProps) => {
  if (!isActive) return;
  if (theme.client === 'solyco') {
    return theme.colors.primary[500];
  }
  switch (href) {
    case '/current-offerings': {
      return 'linear-gradient(45deg, #BDF420, #12A08F)';
    }
    case '/dashboard': {
      return 'linear-gradient(to bottom right, #20F8F8, #017AE9)';
    }
    case '/documents': {
      return 'linear-gradient(to top left, #370DB0, #9854EF)';
    }
    case '/tax-center': {
      return 'linear-gradient(to bottom right, #FF6767, #AD0015)';
    }
    case '/settings': {
      return 'linear-gradient(to bottom right, #AAAAAA, #222222)';
    }
    case '/signout': {
      return 'linear-gradient(to top left, #AAAAAA, #222222)';
    }
    case '/': {
      return 'linear-gradient(to top left, #AAAAAA, #222222)';
    }
    default: {
      return theme.colors.grey[0];
    }
  }
};

export default function NavbarLink({
  mode,
  label,
  href,
  withoutLink,
  handleClick,
}: NavbarLinkProps) {
  const theme = useTheme();
  const router = useRouter();
  const isCurrentPath = router.pathname === href;
  const [isActive, setIsActive] = useState(isCurrentPath);

  const handleMouseEnter = () => {
    if (isCurrentPath) return;
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    if (isCurrentPath) return;
    setIsActive(false);
  };

  const isWithoutLink = Boolean(withoutLink || isCurrentPath);

  const item = (
    <NavbarLinkWrapper
      mode={mode}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={isCurrentPath ? undefined : handleClick}
      isActive={isCurrentPath}
      activeBarColor={getActiveBarColor({
        href,
        isActive: isCurrentPath,
        theme,
      })}
    >
      {getNavbarLinkIcon({ href, isActive, theme })}
      <span data-cy="summary">{label}</span>
    </NavbarLinkWrapper>
  );

  return isWithoutLink ? item : <Link href={href}>{item}</Link>;
}
