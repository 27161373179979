import SolycoFooter from 'modules/salesrep/shared/components/SolycoFooter';
import { useClient } from 'modules/settings/providers/ClientProvider';
import { forwardRef } from 'react';
import FooterV2 from 'stories/Footer';
import {
  SalesrepDesktopNavigation,
  SalesRepMobileNavigation,
} from 'ui/SalesrepNavigation';

import { DataType, FooterParamsType } from '../types';
import { FooterWrapper } from './styles';

interface FooterV1Props extends FooterParamsType {
  isMobile?: boolean;
}

function FooterV1({ isMobile, hideLeft, hideBottom }: FooterV1Props) {
  const { client } = useClient();
  return (
    <>
      {isMobile ? (
        <SalesRepMobileNavigation />
      ) : (
        <>
          {!hideLeft && <SalesrepDesktopNavigation />}
          {!hideBottom && client === 'solyco' && <SolycoFooter />}
        </>
      )}
    </>
  );
}

interface FooterProps {
  isMobile?: boolean;
  variant: number;
  data: DataType;
  footerParams?: FooterParamsType;
}

const Footer = forwardRef<HTMLDivElement, FooterProps>(
  ({ isMobile, variant, footerParams }, ref) => {
    const { client } = useClient();

    if (!variant) return null;

    return (
      <FooterWrapper ref={ref}>
        {variant === 1 && <FooterV1 isMobile={isMobile} {...footerParams} />}
        {variant === 2 && (
          <FooterV2
            client={client}
            backgroundColor={footerParams?.backgroundColor}
          />
        )}
      </FooterWrapper>
    );
  }
);

export default Footer;
