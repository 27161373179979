import { RefObject, useEffect } from 'react';

export const useResizeObserver = (
  ref: RefObject<Element>,
  callback: (rect: DOMRectReadOnly) => void
) => {
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        callback(entry.contentRect);
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [ref, callback]);
};
