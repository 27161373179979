import { AuthorizedRoutes } from 'interfaces';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { authCheckerSSR } from 'utils/helpers/authCheckerSSR';

export function isUrlAllowed(
  targetUrl: string,
  allowedUrls: string[]
): boolean {
  const normalizedTargetUrl = targetUrl.endsWith('/')
    ? targetUrl.slice(0, -1)
    : targetUrl;

  const normalizedAllowedUrls = allowedUrls.map((url) =>
    url.endsWith('/') ? url.slice(0, -1) : url
  );

  return normalizedAllowedUrls.some((allowedUrl) => {
    const regexPattern = allowedUrl
      .split('/')
      .map((segment) => (segment === '*' ? '[^/]+' : escapeRegExp(segment)))
      .join('/');

    const regex = new RegExp(`^${regexPattern}(\\?.*)?$`);

    return regex.test(normalizedTargetUrl);
  });
}

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const useAuthRouter = (authorizedRoutes: AuthorizedRoutes) => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!isUrlAllowed(router.pathname, authorizedRoutes.routes)) {
      router.push('/');
    } else {
      setLoading(false);
    }
  }, [router]);

  return { loading };
};

export function withGetServerSideProps() {
  return async (context: GetServerSidePropsContext) => {
    const authorizedRoutes = await authCheckerSSR(context);
    if (!isUrlAllowed(context.resolvedUrl, authorizedRoutes.routes)) {
      return {
        redirect: {
          destination: authorizedRoutes.defaultRoute,
          permanent: false,
        },
      };
    }
    return { props: {} };
  };
}
